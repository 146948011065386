








































































import Vue from "vue";
import Terms from "../components/Terms.vue";
export default Vue.extend({
	name: "Register",
	components: {
		Terms
	},
	data() {
		return {
			email: "" as string,
			password: "",
			checkPassword: "",
			name: "",
			code: "",
			profileImage: null as any,

			errorAlert: ""
		};
	},
	created() {
		this.email = (this.$route.query.email as string) || "";
		this.code = (this.$route.query.token as string) || "";
	},
	methods: {
		sendEmail() {
			this.$store.commit("pushLoading", {
				name: "SEND_REGISTER_EMAIL",
				message: "이메일 보내는 중"
			});
			this.$store
				.dispatch("SEND_REGISTER_EMAIL", { email: this.email })
				.then(data => {
					this.$store.commit("clearLoading", "SEND_REGISTER_EMAIL");
					this.$store.commit("showNotice", `${this.email} 메일함을 확인해주세요.`);
					this.$router.replace("/login");
				})
				.catch(err => console.log(err));
		},
		encodeBase64ImageFile(image: File): Promise<string> {
			return new Promise<string>((resolve, reject) => {
				if (!image) return resolve("");
				let reader = new FileReader();
				reader.readAsDataURL(image);
				reader.onload = (event: any) => {
					resolve(event.target.result);
				};
				reader.onerror = error => {
					reject(error);
				};
			});
		},
		onChangeFile(e: any) {
			this.profileImage = e.target.files[0];
		},
		register() {
			if (this.isEmailAuth) {
				if (this.password != this.checkPassword) {
					this.errorAlert = "비밀번호가 일치하지 않습니다.";
				} else {
					this.$store.commit("pushLoading", {
						name: "REGISTER",
						message: "회원가입 하는 중"
					});
					this.encodeBase64ImageFile(this.profileImage)
						.then(img => {
							this.$store
								.dispatch("REGISTER", {
									email: this.email,
									password: this.password,
									name: this.name,
									code: this.code
								})
								.then(token => {
									this.$store.commit("showNotice", "회원가입에 성공하였습니다.");
									if (img) {
										this.$store
											.dispatch("SET_PROFILE_IMAGE", {
												img
											})
											.then(() => {
												this.$store.commit("clearLoading", "REGISTER");
												this.$router.replace("/login");
											})
											.catch(err => {
												this.$store.commit("clearLoading", "REGISTER");
												this.errorAlert = err.response.data.message;
											});
									} else {
										this.$store.commit("clearLoading", "REGISTER");
										this.$router.replace("/login");
									}
								})
								.catch(err => {
									this.$store.commit("clearLoading", "REGISTER");
									this.errorAlert = err.response.data.message;
								});
						})
						.catch(err => {
							this.$store.commit("clearLoading", "REGISTER");
							this.errorAlert = err.response.data.message;
						});
				}
			}
		}
	},
	computed: {
		isEmailAuth() {
			return this.$route.query.token && this.$route.query.email;
		}
	}
});
